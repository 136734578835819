import { useRef } from 'react';
import getConfig from 'next/config';
import { Container } from '../../../landing.common';
import styles from './styles.module.scss';

const LOANS_LIST_URL = `${getConfig().publicRuntimeConfig.webPath}/kredity/podbor/`;

export const LoansList = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    return (
        <Container className={styles.loans_list}>
            <div className={styles.loans_list__frame}>
                <iframe ref={iframeRef} src={LOANS_LIST_URL} />
            </div>
        </Container>
    );
};
