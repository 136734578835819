import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { Dialog } from '@sravni/react-design-system';
import { DialogProps } from '@sravni/react-design-system/dist/types/components/Dialog';
import {
    ContentProps,
    FooterProps,
    HeaderProps,
} from '@sravni/react-design-system/dist/types/components/Dialog/types/Dialog.types';
import { addDialog, removeDialog, useDialogsSelector } from '../../store/reducers/dialogs';

const DialogStackComponent = ({ children, ...props }: PropsWithChildren<DialogProps>) => {
    const dispatch = useDispatch();
    const { current: id } = useRef(uuid());
    const { stack } = useDialogsSelector();

    useEffect(() => {
        if (props.visible) {
            dispatch(addDialog({ id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible]);

    const handleOnClose = (onClose: DialogProps['onClose']) => (e: any) => {
        dispatch(removeDialog());
        if (onClose) {
            onClose(e);
        }
    };

    return stack[0] === id ? (
        <Dialog {...props} visible={props.visible} onClose={handleOnClose(props.onClose)}>
            {children}
        </Dialog>
    ) : null;
};

interface ICompoundedComponent extends React.ForwardRefExoticComponent<DialogProps & React.RefAttributes<HTMLElement>> {
    Header: React.ForwardRefExoticComponent<HeaderProps & React.RefAttributes<HTMLDivElement>>;
    Footer: React.ForwardRefExoticComponent<ContentProps & React.RefAttributes<HTMLDivElement>>;
    Content: React.ForwardRefExoticComponent<FooterProps & React.RefAttributes<HTMLDivElement>>;
}

/**
 *  Компонент работает как обычный Dialog из ДС, но при одновременно открытии нескольких откроет их по очереди(стак)
 *
 * */
const DialogStack = DialogStackComponent as ICompoundedComponent;
DialogStack.Header = Dialog.Header;
DialogStack.Footer = Dialog.Footer;
DialogStack.Content = Dialog.Content;

export { DialogStack as Dialog };
