import { FC } from 'react';
import { SelectionCalculator } from '@sravni/creditselection-react/lib/SelectionCalculator';
import getConfig from 'next/config';
import { Container } from '../../../landing.common';
import { isProduction } from '../../../../helpers';
import { FORM_FIELDS } from '../../../../constants';
import styles from './styles.module.scss';

interface IProps {
    landingData: FE.FormData;
}

export const Calculator: FC<IProps> = (props) => {
    const { landingData } = props;
    return (
        <Container className={styles.calculator}>
            <SelectionCalculator
                baseUrl={getConfig().publicRuntimeConfig.webPath}
                enableAnalytics={isProduction ? true : 'logs'}
                defaultFormValues={{
                    amount: landingData[FORM_FIELDS.AMOUNT],
                    period: landingData[FORM_FIELDS.PERIOD],
                    purpose: landingData[FORM_FIELDS.PURPOSE],
                    rate: 31,
                }}
            />
        </Container>
    );
};
