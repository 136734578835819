import { FC, useCallback, useEffect } from 'react';
import { Logo as SravniLogo, Alert, Icon, Avatar, Typography, Button } from '@sravni/react-design-system';
import { Rows, Row, Col } from '../../landing.common';
import styles from '../styles.module.scss';
import CianLogo from '../../form.common/assets/cian_logo_large.svg';
import CrossLogos from '../../form.common/assets/cross.svg';
import { Bank, Clock, RubleSign, RubleHouse, ShieldLock } from '@sravni/react-icons';
import { AlertProps } from '@sravni/react-design-system/dist/types/components/Alert';
import { useInView } from 'react-intersection-observer';
import { EventType, useAnalytics } from '../../hooks/analytics';
import { useFormFields } from '../../../store/selectors/formStore';
import { FORM_FIELDS } from '../../../constants';
import { CIAN_ADV_LINK_B } from '../../form/steps/params/components/mortgage.cian.banner/constants';

interface BenefitItem {
    text: React.ReactNode | string;
    icon: React.ReactNode;
    backgroundColor: AlertProps['color'];
}

const { Text } = Typography;

const cianAdvantagesList: BenefitItem[] = [
    {
        text: (
            <Text size={12}>
                Заявка в <b>8&nbsp;банков</b>
            </Text>
        ),
        icon: <Bank />,
        backgroundColor: 'violet',
    },
    {
        text: (
            <Text size={12}>
                Решение через&nbsp;<b>2&nbsp;мин.</b>
            </Text>
        ),
        icon: <Clock />,
        backgroundColor: 'blue',
    },
    {
        text: (
            <Text size={12}>
                Первый взнос от&nbsp;<b>10%</b>
            </Text>
        ),
        icon: <RubleSign />,
        backgroundColor: 'orange',
    },
    {
        text: (
            <Text size={12}>
                Сумма кредита до&nbsp;<b>70&nbsp;млн&nbsp;₽</b>
            </Text>
        ),
        icon: <RubleHouse />,
        backgroundColor: 'green',
    },
];

export const MortgageView: FC = () => {
    const { ref, inView } = useInView({ threshold: 1, triggerOnce: true });
    const { pushEvent } = useAnalytics();
    const [region] = useFormFields([FORM_FIELDS.REGION]);

    useEffect(() => {
        if (inView) {
            pushEvent({
                event: EventType.mainEvent,
                eventCategory: 'Подбор кредита',
                eventAction: 'Показ баннера с Циан',
                eventLabel: region,
                eventValue: undefined,
            });
        }
    }, [inView, pushEvent]);

    const onClick = useCallback(() => {
        pushEvent({
            event: EventType.mainEvent,
            eventCategory: 'Подбор кредита',
            eventAction: 'Переход в Циан',
            eventLabel: region,
            eventValue: undefined,
        });
        window.open(CIAN_ADV_LINK_B, '_blank');
    }, [region, pushEvent]);

    return (
        <div ref={ref}>
            <Rows gap="24" mobileGap="16">
                <Rows gap="16" mobileGap="16">
                    <Row>
                        <Col>
                            <div className={styles['logos-container']}>
                                <CianLogo className={styles['cian-logo']} />
                                <CrossLogos />
                                <SravniLogo className={styles['sravni-logo']} />
                            </div>
                        </Col>
                    </Row>
                    <Row gap="12" mobileGap="4">
                        {cianAdvantagesList.map((item, i) => (
                            <Col sm-6 md-3 key={i}>
                                <Alert
                                    className={styles['mortgage-item']}
                                    variant="secondary"
                                    color={item.backgroundColor}
                                    icon={
                                        <Avatar size={36} className={styles['mortgage-item__icon']}>
                                            <Icon icon={item.icon} color={item.backgroundColor as any} />
                                        </Avatar>
                                    }
                                    subtitle={item.text}
                                />
                            </Col>
                        ))}
                    </Row>
                </Rows>
                <Row gap="24" mobileGap="12">
                    <Col className={styles['mortgage-btn-container']}>
                        <Button
                            size={52}
                            variant="primary"
                            type="button"
                            className={styles['mortgage-btn']}
                            onClick={onClick}>
                            Узнать ставку
                        </Button>
                    </Col>
                    <Col md-10 className={styles['safe-text-container']}>
                        <div className={styles['safe-text']}>
                            <Icon color="blue" icon={<ShieldLock />} />
                            <Text size={12}>Ваши данные защищены и не передаются в банки без вашего согласия</Text>
                        </div>
                    </Col>
                </Row>
            </Rows>
        </div>
    );
};
