import { useIsMobile } from '@sravni/react-utils';
import { FC } from 'react';
import { Space } from '@sravni/react-design-system';
import { Hero } from './components/hero';
import { Calculator } from './components/calculator';
import { Cards } from './components/cards';
import { LoansList } from './components/loans.list';
import { GoToAppButton } from './components/app.button';
import styles from './styles.module.scss';

interface IProps {
    hideLanding: () => void;
    landingData: FE.FormData;
    setLandingData: (landingData: FE.FormData) => void;
}

export const LandingCreditOffers: FC<IProps> = (props) => {
    const { landingData } = props;
    const isMobile = useIsMobile();

    return (
        <Space className={styles.wrapper} size={isMobile ? 0 : 32} direction="vertical">
            <Space size={isMobile ? 16 : 24} direction="vertical">
                <Hero />
                <Calculator landingData={landingData} />
                <Cards />
            </Space>
            <LoansList />
            <GoToAppButton />
        </Space>
    );
};
