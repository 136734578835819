import { Card } from '@sravni/react-design-system';
import { useDispatch } from 'react-redux';
import { useFormData, useFormStore } from '../../store/selectors/formStore';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDictionary } from '../../store/reducers/dictionaries';
import { getLandingFormData } from './helpers';
import { extendFormData } from '../../store/reducers/formStore';
import { FORM_FIELDS, LABELS } from '../../constants';
import { getValidFields } from '../../helpers/form.new';
import { SelectInput, Rows, Row, Col } from '../landing.common';
import { useAutoFill } from '../form/hooks/autofill';
import { useLocal } from '../form/hooks/local';
import { useQueryData } from '../form/hooks/queryData';
import { useIsMobile } from '@sravni/react-utils';
import { PeriodAndAmountView, MortgageView, DefaultView } from './components';
import styles from './styles.module.scss';
import { PURPOSE_SOURCE_VALUES } from '../../constants/sources';

interface Props {
    onSubmit?: () => void;
    withLocalData?: boolean;
    withQueryData?: boolean;
    withAutoFill?: boolean;
}

export const LandingFormView: React.FC<Props> = (props) => {
    const { onSubmit, withLocalData = true, withQueryData = true, withAutoFill = true } = props;

    useAutoFill(withAutoFill);
    useLocal(withLocalData);
    useQueryData(withQueryData);

    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const { disabledFields = [] } = useFormStore();
    const formData = useFormData();

    const purposes = useDictionary('purposes');
    const purposeOptions = useMemo(() => purposes.map(({ name, value }) => ({ value, label: name })), [purposes]);

    const form = useForm({
        resolver: yupResolver(schema()),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const {
        watch,
        control,
        formState: { errors, dirtyFields: _ },
        trigger,
        reset,
        handleSubmit,
    } = form;

    useEffect(() => {
        reset(getLandingFormData(formData), { keepErrors: true, keepDirtyValues: true });
    }, [formData, reset]);

    const formOnBlur: React.FocusEventHandler<HTMLFormElement> = useCallback(
        async (event) => {
            const { name: fieldName } = event.target;
            if (!fieldName) {
                return;
            }
            await trigger(fieldName);
            const validFields = getValidFields(form);
            dispatch(extendFormData(validFields));
        },
        [form, dispatch, trigger],
    );

    const goToForm = useCallback(async () => {
        const isValid = await trigger();
        if (!isValid) {
            return;
        }
        dispatch(
            extendFormData({
                [FORM_FIELDS.AGREEMENT]: true,
            }),
        );
        onSubmit && onSubmit();
    }, [trigger, dispatch, onSubmit]);

    const [purpose] = watch([FORM_FIELDS.PURPOSE]);

    const isMortgagePurposes = useMemo(() => [PURPOSE_SOURCE_VALUES.MORTGAGE].includes(purpose), [purpose]);

    return (
        <FormProvider {...form}>
            <Card size={24} className={styles['landing-form']}>
                <form onBlur={formOnBlur} onSubmit={handleSubmit(goToForm)}>
                    <Rows gap={isMortgagePurposes ? '24' : '16'} mobileGap={isMortgagePurposes ? '16' : '12'}>
                        <Row gap="12">
                            <Col md-4>
                                <Controller
                                    control={control}
                                    name={FORM_FIELDS.PURPOSE}
                                    render={({ field }) => (
                                        <SelectInput
                                            {...field}
                                            label={LABELS[FORM_FIELDS.PURPOSE]}
                                            disabled={disabledFields.includes(FORM_FIELDS.PURPOSE)}
                                            options={purposeOptions}
                                            errorMessage={errors[FORM_FIELDS.PURPOSE]?.message}
                                            withCrossIcon={false}
                                        />
                                    )}
                                />
                            </Col>
                            {!isMobile && !isMortgagePurposes && <PeriodAndAmountView />}
                        </Row>
                        {isMortgagePurposes ? <MortgageView /> : <DefaultView />}
                    </Rows>
                </form>
            </Card>
        </FormProvider>
    );
};
